import { useState } from "react";
import React from "react";
import GraceIshorImage from '../../assets/img/GraceIshor.png'

export default function BlogQuoteLayout() {

    const [state, setState] = useState(false)


    return (
        <>
            <section className="py-20">
                <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex md:px-8">
                    <div className="flex-none mt-14 md:mt-0 md:max-w-sm" data-aos="fade-right">
                        <img
                            src={GraceIshorImage}
                            className=" md:rounded-br-[90px] sm:rounded-br-[90px]"
                            alt="Ceo"
                        />
                    </div>
                    <div className="flex-none space-y-5 px-4 py-6 sm:max-w-lg md:px-0 lg:max-w-[46rem]" data-aos="fade-left" data-aos-delay="300">

                        <blockquote class="sm:flex lg:block">
                            <svg width="24" height="18" viewBox="0 0 24 18" aria-hidden="true" class="flex-shrink-0 text-[#3E885B]">
                                <path d="M0 18h8.7v-5.555c-.024-3.906 1.113-6.841 2.892-9.68L6.452 0C3.188 2.644-.026 7.86 0 12.469V18zm12.408 0h8.7v-5.555C21.083 8.539 22.22 5.604 24 2.765L18.859 0c-3.263 2.644-6.476 7.86-6.451 12.469V18z" fill="green" />
                            </svg>
                            <div class="mt-8 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-10">
                                <p class="text-lg text-justify">
                                    Here at SynthBit Technologies, we're not just building a business, we're crafting a legacy of innovation and excellence. Every day, our team rises to the challenge, driven by a shared passion for pushing boundaries and shaping the future. Together, we're not just meeting expectations, we're exceeding them, fueled by our unwavering commitment to delivering unparalleled value to our customers and making a lasting impact on the world. Our mission is clear: to pioneer change and inspire progress. With dedication and vision, we're shaping a brighter tomorrow through innovation and unwavering determination.
                                </p>
                                <cite class="mt-4 block font-semibold not-italic text-[#3E885B]"> Ishor Bhattrai, CEO, SynthBit Technologies</cite>
                            </div>
                            <svg width="24" height="18" viewBox="0 0 24 18" aria-hidden="true" class="flex-shrink-0 text-black float-end rotate-180">
                                <path d="M0 18h8.7v-5.555c-.024-3.906 1.113-6.841 2.892-9.68L6.452 0C3.188 2.644-.026 7.86 0 12.469V18zm12.408 0h8.7v-5.555C21.083 8.539 22.22 5.604 24 2.765L18.859 0c-3.263 2.644-6.476 7.86-6.451 12.469V18z" fill="green" />
                            </svg>
                        </blockquote>


                    </div>

                </div>
            </section>
        </>
    )
}




