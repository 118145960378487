import React from "react";
import graceImage from "../../assets/img/Grace-Logo-white-300x101.png";
import butwalBazzar from "../../assets/img/butwalBazar.png";
import fumeImage from "../../assets/img/FUME C.png";
import ranaTravels from "../../assets/img/Rana travels.png";

export default function TrustedLayout() {
  return (
    <div className=" py-32  bg-indigo-50 col-start-auto">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div
          className="max-w-screen-xl mx-auto text-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h3 className="text-[#3E885B] text-3xl font-semibold sm:text-4xl">
            Trusted By Big Organization, Companies & StartUps
          </h3>
          <p className=" mt-3 mx-auto max-w-xl">
            At SynthBit, we are committed to delivering high-quality products
            tailored to meet the diverse needs of companies. Our approach
            involves close collaboration with our clients, fostering strong
            relationships built on trust and communication.
          </p>
        </div>
        <div className="mt-12 flex justify-center">
          <ul className="inline-grid grid-cols-2 gap-x-10 gap-y-6 md:gap-x-16 md:grid-cols-3 lg:grid-cols-4">
            {/* LOGO 1 */}
            <li data-aos="fade-right" data-aos-delay="800">
              <img src={graceImage} alt="" className="w-auto h-10" />
            </li>

            {/* LOGO 2 */}
            <li data-aos="fade-right" data-aos-delay="800">
              <img src={butwalBazzar} alt="butwal" className="w-auto h-10" />
            </li>

            {/* LOGO 3 */}
            <li data-aos="fade-left" data-aos-delay="800">
              <img src={fumeImage} alt="fumeCoffe" className="w-auto h-10" />
            </li>

            {/* LOGO 4 */}
            <li data-aos="fade-left" data-aos-delay="800">
              <img
                src={ranaTravels}
                alt="Rana travels"
                className="w-auto h-12"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
