import React from "react";
import { useEffect } from "react";
import { Carousel } from "flowbite-react";
import sliderimageOne from "../../assets/img/computerIcon.png";
import collabImage from "../../assets/img/collabaration.png";
import UIUXImage from "../../assets/img/UIUX.png";
import SeoImage from "../../assets/img/seo.png";
import TechnologiesImage from "../../assets/img/technologies.png";

import AOS from "aos";
import "aos/dist/aos.css";

export default function HeroSectionLayout() {
  useEffect(() => {
    AOS.init({ duration: 2000 }); // Initialize AOS
  }, []);

  useEffect(() => {
    AOS.refresh({ duration: 2000 });
  }, []);

  return (
    <section className="mt-24 mx-auto max-w-screen-xl pb-36 px-4 items-center lg:flex md:px-8">
      <div className="space-y-4 flex-1 sm:text-center lg:text-left">
        <h1
          className="text-white font-bold text-4xl xl:text-7xl pb-4 "
          data-aos="fade-up"
          data-aos-delay="0"
        >
          <span className="text-[#3E885B]">Collaborating & Computing </span>
        </h1>
        <p
          className=" max-w-xl  leading-relaxed sm:mx-auto lg:ml-0"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          We elevate user experiences, providing businesses a competitive edge
          through seamless, technology-driven growth.
        </p>
        <div className="pt-8 items-center justify-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex lg:justify-start">
          <a
            href="javascript:void(0)"
            className="px-7 py-3 w-full bg-[#3E885B] text-white text-center rounded-md shadow-md block sm:w-auto"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            Get Started
          </a>
        </div>
      </div>
      <div
        className="flex-1 text-center mt-7 lg:mt-0 lg:ml-3 "
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div className="h-80 sm:h-80 md:h-80 xl:h-80 2xl:h-96 relative ">
          <Carousel
            indicators={false}
            leftControl=" "
            rightControl=" "
            slideInterval={2000}
          >
            <img
              src={TechnologiesImage}
              className="w-auto mx-auto sm:w-auto lg:w-auto md:h-96 md:w-auto sm:h-80"
            />
            <img
              src={sliderimageOne}
              alt="..."
              className="w-auto mx-auto sm:w-auto lg:w-auto md:h-96 md:w-auto sm:h-80"
            />
            <img
              src={collabImage}
              alt="..."
              className="w-auto mx-auto sm:w-auto lg:w-auto md:h-96 md:w-auto sm:h-80"
            />
            <img
              src={UIUXImage}
              alt="..."
              className="w-auto mx-auto sm:w-auto lg:w-auto md:h-96 md:w-auto sm:h-80"
            />
            <img
              src={SeoImage}
              alt="..."
              className="w-auto mx-auto sm:w-auto lg:w-auto md:h-96 md:w-auto sm:h-80"
            />
          </Carousel>
        </div>
      </div>
    </section>
  );
}
