import React, { useState } from 'react';

const TabPanel = ({ children, active }) => (
  <div className={`${active ? 'block' : 'hidden'} bg-black`}>{children}</div>
);

function ServicesLayout() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };


  return (
    <>
      {/* <div className="container mx-auto">
        {/* <div className="pt-2 text-center pb-3 md:text-2xl xl:text-4xl">
          <span className='text-green-600 xl:text-4xl md:text-2xl'> Technologies</span>  we work with

        </div> 
        <div className="flex">
          <Tab label="Design" imageSrc={IconDesign} active={activeTab === 1} onClick={() => handleTabClick(1)} />
          <Tab label="SEO" imageSrc={IconSeo} active={activeTab === 2} onClick={() => handleTabClick(2)} />
          <Tab label="Production & Technology" imageSrc={IconPoTech} active={activeTab === 3} onClick={() => handleTabClick(3)} />
          <Tab label="Maintanance" imageSrc={IconMaintain} active={activeTab === 4 } onClick={() => handleTabClick(4)} />
        </div>
      </div>

      <div className="technologies bg-black">
        <div className="container mx-auto">


          <div className="">
            <TabPanel active={activeTab === 1}  >
              <div className={`py-20 grid md:grid-cols-1 sm:grid-cols-1 px-2 `}>
                <div className="content pr-3">
                  <div className="heading-sub py-2 text-xl text-white">
                    Design
                  </div>
                  <div className="text-white font-semibold pt-3 pb-6 text-6xl">
                    Productive and Creativity <br /> with quality assurance.
                  </div>
                  <div className=" text-white text-3xl text-justify py-6">
                    We specialize in creating websites that are not only visually pleasing but also highly functional.
                    We specialize in creating user interfaces and digital products that are intuitive, easy to navigate

                  </div>


                  <div className="button">
                    <button className=' px-4 py-3  bg-[#4484c2] shadow-md  text-white'>Explore design </button>
                  </div>
                </div>
                <div className="content py-4 flex justify-center">
                  <img src={design} alt="design here"/>
                </div>

              </div>
            </TabPanel>
            <TabPanel active={activeTab === 2}>
            <div className="py-20 grid md:grid-cols-2 sm:grid-cols-1 px-2">
                <div className="content pr-3">
                  <div className="heading-sub py-2 text-xl text-white">
                    Search Engine Optimization
                  </div>
                  <div className="text-white font-semibold pt-3 pb-6 text-6xl">
                    Product Ready To Overtake Every other Products
                  </div>
                  <div className=" text-white text-3xl text-justify py-6">
                  Empowering small businesses with innovative solutions, SynthBit Technology provides cutting-edge software for streamlined operations and accelerated growth.

                  </div>


                  <div className="button">
                    <button className=' px-4 py-3  bg-[#4484c2] shadow-md  text-white'>Explore design </button>
                  </div>
                </div>
                <div className="content flex justify-center">
                  <img src={Seo} alt="" className='w-auto ' />
                </div>

              </div>
            </TabPanel>
            <TabPanel active={activeTab === 3}>
            <div className="py-20 grid md:grid-cols-2 sm:grid-cols-1 px-2">
                <div className="content pr-3">
                  <div className="heading-sub py-2 text-xl text-white">
                    Search Engine Optimization
                  </div>
                  <div className="text-white font-semibold pt-3 pb-6 text-6xl">
                    Productive and Creativity <br /> with quality assurance.
                  </div>
                  <div className=" text-white text-3xl text-justify py-6">
                    We specialize in creating websites that are not only visually pleasing but also highly functional.
                    We specialize in creating user interfaces and digital products that are intuitive, easy to navigate

                  </div>


                  <div className="button">
                    <button className=' px-4 py-3  bg-[#4484c2] shadow-md  text-white'>Explore design </button>
                  </div>
                </div>
                <div className="content flex justify-center ">
                  <img src={SoftDLC} alt="" className='w-auto' />
                </div>

              </div>
            </TabPanel>

            <TabPanel active={activeTab === 4}>
            <div className="py-20 grid md:grid-cols-2 sm:grid-cols-1 px-2">
                <div className="content pr-3">
                  <div className="heading-sub py-2 text-xl text-white">
                    Maintanance
                  </div>
                  <div className="text-white font-semibold pt-3 pb-6 text-6xl">
                    Product Ready To Overtake Every other Products
                  </div>
                  <div className=" text-white text-3xl text-justify py-6">
                  Empowering small businesses with innovative solutions, SynthBit Technology provides cutting-edge software for streamlined operations and accelerated growth.

                  </div>


                  <div className="button">
                    <button className=' px-4 py-3  bg-[#4484c2] shadow-md  text-white'>Explore design </button>
                  </div>
                </div>
                <div className="content flex justify-center">
                  <img src={Seo} alt="" className='w-auto ' />
                </div>

              </div>
            </TabPanel>
          </div>
        </div>
      </div> */}

    </>
  )
}

export default ServicesLayout
