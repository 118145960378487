import React from "react";
import AmritdaiImage from "../../assets/img/Amritkhanal.jpeg";
import sanjogImage from "../../assets/img/sanjog.jpg";
import bijayImage from "../../assets/img/Bijay.jpeg";
import abhiImage from "../../assets/img/abhi.jpg";

export default function TeamsLayout() {
  const OurTeam = [
    {
      image: `${AmritdaiImage}`,
      name: "Amrit Khanal",
      Position: "Business Analyst",
      social: [
        {
          linked: "https://www.linkedin.com/ishor_bhattrai",
          faceBook: "https://www.facebook.com/ishorbhattrai",
          instagram: "",
          twitter: "",
        },
      ],
    },
    {
      image: `${sanjogImage}`,
      name: "Sanjok Gyawali",
      Position: "Operation Head & Sr. Software Enginner",
      social: [
        {
          linked: "https://www.linkedin.com/in/sanjokgyawali/",
          faceBook: "https://facebook.com/sanjok.gyanwali",
          instagram: "https://www.instagram.com/sanzok_gyawali/",
        },
      ],
    },
    {
      image: `${bijayImage}`,
      name: "Bijay Chaudhary",
      Position: "Full Stack Developer & Tutor",
      social: [
        {
          linked: "",
          faceBook: "",
          instagram: "",
          twitter: "",
        },
      ],
    },
    {
      image: `${abhiImage}`,
      name: "Abhi Lamichane",
      Position: "Data Science & Machine Learning Tutor",
      social: [
        {
          linked: "",
          faceBook: "",
          instagram: "",
          twitter: "",
        },
      ],
    },
  ];

  return (
    <>
      <section class="flex items-center bg-stone-100 xl:h-screen py-20">
        <div class="justify-center flex-1 px-4 py-14 mx-auto max-w-7xl lg:py-4 mt-32 mb-32 md:px-6">
          <div class="text-center my-24">
            <h1 class="text-[#3E885B] text-3xl font-semibold sm:text-4xl">
              {" "}
              Meet Our Wonderful Team{" "}
            </h1>
            <p className=" mt-3 mx-auto max-w-xl">
              We are a team of passionate developers and designers who are
              always striving to make the web better.
            </p>
          </div>
          <div class="flex flex-wrap justify-center -mx-4 my-24">
            {OurTeam.map((data, index) => {
              return (
                <div
                  class="w-full px-4 mb-10 sm:w-1/2 lg:w-1/3 xl:w-1/4 "
                  key={index}
                >
                  <div class="mx-auto text-center ">
                    <div class="inline-block mb-3 overflow-hidden text-xs text-white bg-blue-500 rounded-full w-32 h-32 sm:w-40 sm:h-40">
                      <img
                        class="object-cover w-full h-full transition-all hover:scale-110"
                        src={data.image}
                        alt=""
                      />
                    </div>
                    <h2 class="mb-2 text-xl font-semibold text-gray-800 dark:text-gray-300">
                      {data.name}
                    </h2>
                    <span class="inline-block mb-6 text-base font-medium text-blue-500 dark:text-gray-400">
                      {data.Position}
                    </span>
                    {data.social.map((items, index) => {
                      return (
                        <div
                          class="flex items-center justify-center"
                          key={index}
                        >
                          <a
                            class="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400"
                            href={items.faceBook}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              fill="currentColor"
                              class="w-4 h-4 text-blue-800 bi bi-facebook dark:text-gray-400"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                            </svg>
                          </a>
                          <a
                            class="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400"
                            href={items.twitter}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              fill="currentColor"
                              class="w-4 h-4 text-blue-500 dark:text-gray-400 bi bi-twitter"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                            </svg>
                          </a>
                          <a
                            class="inline-block text-coolGray-300 hover:text-coolGray-400"
                            href={items.instagram}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10s"
                              fill="currentColor"
                              class="w-4 h-4 text-pink-600 dark:text-gray-400 bi bi-instagram"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                            </svg>
                          </a>
                          <a
                            class="inline-block text-coolGray-300 hover:text-coolGray-400"
                            href={items.linked}
                          >
                            <svg
                              className="w-4 h-4 ml-5 duration-150"
                              fill="none"
                              viewBox="0 0 48 48"
                            >
                              <g clip-path="url(#clip0_17_68)">
                                <path
                                  fill="currentColor"
                                  d="M44.447 0H3.544C1.584 0 0 1.547 0 3.46V44.53C0 46.444 1.584 48 3.544 48h40.903C46.407 48 48 46.444 48 44.54V3.46C48 1.546 46.406 0 44.447 0zM14.24 40.903H7.116V17.991h7.125v22.912zM10.678 14.87a4.127 4.127 0 01-4.134-4.125 4.127 4.127 0 014.134-4.125 4.125 4.125 0 010 8.25zm30.225 26.034h-7.115V29.766c0-2.653-.047-6.075-3.704-6.075-3.703 0-4.265 2.896-4.265 5.887v11.325h-7.107V17.991h6.826v3.13h.093c.947-1.8 3.272-3.702 6.731-3.702 7.21 0 8.541 4.744 8.541 10.912v12.572z"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_17_68">
                                  <path fill="currentColor" d="M0 0h48v48H0z" />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
