import * as Tabs from "@radix-ui/react-tabs";
import React from "react";
import SmartPhoneIcon from '../../assets/svg/Smartphone_icon.svg';
import WebIcon from '../../assets/svg/Network_web_icon.svg';
import CloudIcon from '../../assets/svg/Cloud_icon.svg';
import DataScienceIcon from '../../assets/svg/Datasets_learning_icon.svg';
import SoftwareIcon from '../../assets/svg/Software_dev_icon.svg';
import SoftwareTestingIcon from '../../assets/svg/SoftwareTesting_icon.svg';
import UIUXIcon from '../../assets/svg/UIUX_icon.svg';
import DevOpsIcon from '../../assets/svg/Dev_edit_icon.svg';
import SolidIcon from '../../assets/svg/File_code_icon.svg';
import DeployIcon from '../../assets/svg/Deploy_rules_icon.svg';
import DeadlineIcon from '../../assets/svg/Deadline_icon.svg';
import DocumentIcon from '../../assets/svg/Document_icon.svg';

export default function TabLayout() {

  // const [selectedTab, setSelectedTab] = useState("What We Build");s
  const tabItems = [
    {
      name: "What we Build",
      product: [
        {
          icon: `${SoftwareIcon}`,
          title: "Software",
          desc: "Crafting tailored software solutions aligned with your vision, driving efficiency and innovation through precise coding.",
        },
        {
          icon: `${WebIcon}`,
          title: " Mobile & Web Development",
          desc: "Creating seamless digital journeys with user-centric design, marrying aesthetics and functionality for unparalleled cross-platform experiences.",
        },
        {
          icon: `${SoftwareTestingIcon}`,
          title: "Software Testing",
          desc: "Ensuring impeccable software quality through rigorous testing, assuring reliability and user satisfaction with every release.",
        },
        {
          icon: `${DataScienceIcon}`,
          title: "Data Science and Machine Learning",
          desc: " Unveiling insights from complex data landscapes, empowering strategic decisions and breakthroughs through advanced analytics and machine learning.",
        },
        {
          icon: `${UIUXIcon}`,
          title: "UI & UX Design",
          desc: "Envisioning and crafting intuitive user experiences, where artistic UI meets seamless functionality, elevating brand value and user engagement.",
        },
        {
          icon: `${DevOpsIcon}`,
          title: "Dev-Ops",
          desc: "Building robust foundations and agile workflows for scalability and security while embracing continuous development methodologies.",
        }
      ]

    },


    {
      name: "Technologies",
      product: [
        {
          icon: `${SmartPhoneIcon}`,
          title: "Mobile App",
          desc: " Ionic, React Native, Flutter, java Swift.",
        },
        {
          icon: `${WebIcon}`,
          title: "Web Development",
          desc: " React, Angular, Vue, laravel, Es5 & Es6 WordPress  ",
        },
        {
          icon: `${CloudIcon}`,
          title: "Cloud  Platforms",
          desc: " AWS (EC2, RDS, S3), Google Cloud, Firebase",
        },
        {
          icon: `${DataScienceIcon}`,
          title: "Data Science",
          desc: " Python(Pandas, Numpy, Matplotlib, Seaborn, Scikit-learn) ,R Shiny",
        },

      ]
    },

    {
      name: "Practices",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor voluptate, vitae tempora vero quisquam qui suscipit similique a ipsam quod cupiditate officia labore mollitia asperiores nam libero sunt unde necessitatibus.",
      product: [
        {
          icon: `${UIUXIcon}`,
          title: "Validate Design",
          desc: " We deliver interactive, clickable screens of how final application would look and validate them with stakeholders and users.",
        },
        {
          icon: `${SolidIcon}`,
          title: "Rock Solid Code",
          desc: " We take security and compliance seriously, and we prepare our people for it. We adopt standards or code idioms depending on the language or framework.",
        },
        {
          icon: `${DeployIcon}`,
          title: "Build and Deploy",
          desc: " Continuous integration is tied into rejections of merges and warning messages that are broadcast on Slack. We monitor apps and services to detect issues and scale when needed.",
        },
        {
          icon: `${DeadlineIcon}`,
          title: "Attention to deadline",
          desc: " We work with the proper planning for the inovationa and quality asurance to meet the deadline in the time.",
        },
        {
          icon: `${DocumentIcon}`,
          title: "Proper Documentation",
          desc: "We follow  good documentation practices for better collaboration in commit, code and pull requests.",
        },

      ]
    },
  ];

  return (

    <>
      <section className="mt-24 mx-auto max-w-screen-xl pb-24 px-4 items-center md:px-8">
        <div className="max-w-screen-xl mx-auto text-center pb-10">
          <h3 className="text-[#3E885B] text-3xl font-semibold sm:text-4xl">
            Experties that we practice 
          </h3>
          <p className=" mt-3 mx-auto max-w-xl">
            Our team is composed of experts who are passionate about designing, developing, and launching successful web applications.
          </p>
        </div>

        <div className="tab">
          <Tabs.Root
            className="max-w-screen-xl mx-auto px-4 md:px-8 "

            defaultValue="What we Build"
          >
            <Tabs.List
              className="w-full border-b flex justify-center items-center gap-x-10 overflow-x-auto text-sm sticky top-[85px] bg-white"
              aria-label="Manage your account"
            >
              {tabItems.map((item, idx) => (
                <Tabs.Trigger
                  key={idx}
                  className="group outline-none py-1.5 border-b-2 border-white text-gray-500 data-[state=active]:border-[#4484c2] data-[state=active]:text-[#3E885B]"
                  value={item.name}
                >
                  <div className="flex items-center gap-x-2 py-1.5 px-3 rounded-lg duration-150 group-hover:text-[#3E885B] group-hover:bg-gray-50 group-active:bg-gray-100 font-medium">
                    {item.icon}
                    {item.name}
                  </div>
                </Tabs.Trigger>
              ))}
            </Tabs.List>
            {tabItems.map((item, idx) => (
              <Tabs.Content key={idx} className="py-6" value={item.name}>
                <div className="mt-12">
                  <ul className="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3">
                    {item.product.map((subItem, idx) => (
                      <li key={idx} className="flex gap-x-4">
                        <div className="flex-none w-12 h-12 bg-indigo-50 text-indigo-600 rounded-lg flex items-center justify-center">
                          <img src={subItem.icon} alt="" className="xl:h-8 sm:h-6 md:h-8" />
                        </div>
                        <div>
                          <h4 className="text-lg text-gray-800 font-semibold">
                            {subItem.title}
                          </h4>
                          <p className="mt-3">
                            {subItem.desc}
                          </p>
                        </div>
                      </li>
                    ))
                    }
                  </ul>
                </div>
              </Tabs.Content>
            ))}
          </Tabs.Root>
        </div>

      </section>

    </>

  );
};
