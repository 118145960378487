import React from 'react'
import MainLayout from '../../layouts/MainLayouts'
import developmentImage from '../../../assets/img/development.jpg';
import MlImage from '../../../assets/img/ml.jpg';
import DevOPsImage  from '../../../assets/img/image.jpg';
import DevOpsImage from '../../../assets/img/diverse-programmers-discussing-about-html-code-compiling-discovers-errors-standing-front-multiple-screens-startup-office-software-developers-working-server-interface-development.jpg';
import { Link } from 'react-router-dom';
import ContactLayout from "../../layouts/ContactLayout";

export default function Service() {
  return (
    <>
      <MainLayout>

        <section className="mt-10 mx-auto max-w-screen-xl pb-10 px-4 items-center text-center md:px-8">
          {/* <div className="breadcrume pb-12" >
            Home &nbsp; / &nbsp; <span className='text-[#3E885B]'> Services</span>
          </div> */}
          <div className="heading text-center text-6xl text-[#3E885B]">
            Services
            </div>
            <p className='pt-4 max-w-screen-md mx-auto'> Team composed of experts who are passionate about designing, developing, and launching successful applications.</p>
          
        </section>

        <section className=' bg-indigo-50 col-start-auto'>
          <div className="mt-10 mx-auto max-w-screen-xl py-12 items-center justify-between overflow-hidden md:flex sm:flex md:px-8 px-4">
            <div className="custom-screen flex flex-col-reverse gap-x-12 mt-12 justify-between md:flex-row md:items-center">
              <div className="flex-none max-w-xl mt-12 space-y-3 delay-200 duration-300 ease-in md:mt-0">
                <h2 className=" md:text-xl font-semibold sm:text-xl">
                  Product & Technology
                </h2>
                <div className='pt-1 text-[#3E885B] md:text-5xl font-semibold text-4xl'>
                  Empowering Tomorrow, One Innovation at a Time.
                </div>
                <p className="name">
                  Starboard is a comprehensive project management software that captures the entire project lifecycle, from backlog to release. With a variety of features. Allow your teams to collaborate effectively on projects and stay on top of deadlines.
                </p>

                <div className='pt-1'>
                  <div className="pt-8 items-center justify-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex lg:justify-start">
                    <Link href="javascript:void(0)" className="bg-transparent hover:bg-[#3E885B] text-blue-700 font-semibold hover:text-white py-2 px-4 border border-[#4484c2] hover:border-transparent rounded" data-aos="fade-up" data-aos-delay="400">
                      Read More
                    </Link>

                  </div>
                </div>
              </div>

              <div className="flex-none w-full delay-200 duration-300 ease-in md:max-w-lg">
                <img
                  src={developmentImage}
                  alt="CTA image"
                  className='md:rounded-bl-[90px] shadow-lg rounded-lg border'
                />
              </div>
            </div>
          </div>
        </section>


        <div className="mt-10 mx-auto max-w-screen-xl py-12 items-center justify-between overflow-hidden md:flex sm:flex md:px-8 px-4">
          <div className="custom-screen flex flex-col-reverse gap-x-12 mt-12 justify-between md:flex-row md:items-center">

            <div className="flex-none w-full delay-200 duration-300 ease-in md:max-w-lg">
              <img
                src={DevOPsImage}
                alt="CTA image"
                className='md:rounded-br-[90px] shadow-lg rounded-lg border'
              />
            </div>

            <div className="flex-none max-w-xl mt-12 space-y-3 delay-200 duration-300 ease-in md:mt-0">
              <h2 className=" md:text-xl font-semibold sm:text-xl">
                Design
              </h2>
              <div className='pt-1 text-[#3E885B] md:text-5xl font-semibold text-4xl'>
                Empowering Interaction, Enriching Experience.
              </div>
              <p className="name">
                Starboard is a comprehensive project management software that captures the entire project lifecycle, from backlog to release. With a variety of features. Allow your teams to collaborate effectively on projects and stay on top of deadlines.
              </p>

              <div className='pt-1'>
                <div className="pt-8 items-center justify-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex lg:justify-start">
                  <Link href="javascript:void(0)" className="bg-transparent hover:bg-[#3E885B] text-blue-700 font-semibold hover:text-white py-2 px-4 border border-[#4484c2] hover:border-transparent rounded" data-aos="fade-up" data-aos-delay="400">
                    Read More
                  </Link>
                </div>
              </div>
            </div>


          </div>
        </div>

        <section className='bg-indigo-50 col-start-auto'>
          <div className="mt-10 mx-auto max-w-screen-xl py-12 items-center justify-between overflow-hidden md:flex sm:flex md:px-8 px-4">
            <div className="custom-screen flex flex-col-reverse gap-x-12 mt-12 justify-between md:flex-row md:items-center">
              <div className="flex-none max-w-xl mt-12 space-y-3 delay-200 duration-300 ease-in md:mt-0">
                <h2 className=" md:text-xl font-semibold sm:text-xl">
                  Dev Ops
                </h2>
                <div className='pt-1 text-[#3E885B] md:text-5xl font-semibold text-4xl'>
                  Streamlining Development, Scaling Possibilities
                </div>
                <p className="name">
                  Starboard is a comprehensive project management software that captures the entire project lifecycle, from backlog to release. With a variety of features. Allow your teams to collaborate effectively on projects and stay on top of deadlines.
                </p>

                <div className='pt-1'>
                  <div className="pt-8 items-center justify-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex lg:justify-start">
                    <Link href="javascript:void(0)" className="bg-transparent hover:bg-[#3E885B] text-blue-700 font-semibold hover:text-white py-2 px-4 border border-[#4484c2] hover:border-transparent rounded" data-aos="fade-up" data-aos-delay="400">
                      Read More
                    </Link>

                  </div>
                </div>
              </div>

              <div className="flex-none w-full delay-200 duration-300 ease-in md:max-w-lg">
                <img
                  src={DevOpsImage}
                  alt="CTA image"
                  className='md:rounded-bl-[90px] shadow-lg rounded-lg border'
                />
              </div>
            </div>
          </div>
        </section>

        <div className="mt-10 mx-auto max-w-screen-xl py-12 items-center justify-between overflow-hidden md:flex sm:flex md:px-8 px-4">
          <div className="custom-screen flex flex-col-reverse gap-x-12 mt-12 justify-between md:flex-row md:items-center">

            <div className="flex-none w-full delay-200 duration-300 ease-in md:max-w-lg">
              <img
                src={MlImage}
                alt="CTA image"
                className='md:rounded-br-[90px] shadow-lg rounded-lg border'
              />
            </div>

            <div className="flex-none max-w-xl mt-12 space-y-3 delay-200 duration-300 ease-in md:mt-0">
              <h2 className=" md:text-xl font-semibold sm:text-xl">
                Data Science & Machine Learning
              </h2>
              <div className='pt-1 text-[#3E885B] md:text-5xl font-semibold text-4xl'>
                Transforming Data into Intelligence, Shaping Tomorrow's World.
              </div>
              <p className="name">
                Starboard is a comprehensive project management software that captures the entire project lifecycle, from backlog to release. With a variety of features. Allow your teams to collaborate effectively on projects and stay on top of deadlines.
              </p>

              <div className='pt-1'>
                <div className="pt-8 items-center justify-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex lg:justify-start">
                  <Link href="javascript:void(0)" className="bg-transparent hover:bg-[#3E885B] text-blue-700 font-semibold hover:text-white py-2 px-4 border border-[#4484c2] hover:border-transparent rounded" data-aos="fade-up" data-aos-delay="400">
                    Read More
                  </Link>

                </div>
              </div>
            </div>


          </div>
        </div>

      <ContactLayout></ContactLayout>

        {/* <div className="mt-10 mx-auto max-w-screen-xl pb-24 px-4 items-center md:px-8 ">
          <section class="container mx-auto py-10 md:py-20 antialiased ">
            <section class="grid lg:grid-cols-2 2xl:grid-cols-4 grid-cols-1 gap-8">
              <article class="mx-auto max-w-sm pb-8 bg-cover bg-center cursor-pointer transform duration-500 hover:-translate-y-1 shadow-2xl rounded-xl">
                <img class="mx-auto mb-20 mt-10 w-40" src="https://penpot.app/images/cross-teams.webp" alt="" />
                <h2 class="text-center text-3xl mt-8 font-bold min-h-18 px-12">
                  For cross-domain teams
                </h2>
                <p class="m-4 text-lg p-4 leading-relaxed text-center ">
                  Product features and capabilities meant for the different roles in the next-decade team. Say goodbye to the legendary pain of the design silo.
                </p>
              </article>

              <article class="mx-auto max-w-sm pb-8 bg-cover bg-center cursor-pointer transform duration-500 hover:-translate-y-1 shadow-2xl rounded-xl">
                <img class="mx-auto mb-20 mt-10 w-40" src="https://penpot.app/images/open-standards.webp" alt="" />
                <h2 class="text-center text-3xl mt-8 font-bold min-h-18 px-12">
                  With love and Open Standards
                </h2>
                <p class="m-4 text-lg p-4 leading-relaxed text-center">
                  Using SVG as no other prototyping tool does, Penpot files sport compatibility with most of the vectorial tools, are tech friendly and extremely easy to use in web.
                </p>
              </article>

              <article class="mx-auto max-w-sm pb-8 bg-cover bg-center cursor-pointer transform duration-500 hover:-translate-y-1 shadow-2xl rounded-xl">
                <img class="mx-auto mb-20 mt-10 w-40" src="https://penpot.app/images/multi-platforms.webp" alt="" />
                <h2 class="text-center text-3xl mt-8 font-bold min-h-18 px-12">
                  Multiplatform
                </h2>
                <p class="m-4 text-lg p-4 leading-relaxed text-center ">
                  Being web based, Penpot is not dependent on operating systems or installations, you will only need to run a modern browser.
                </p>
              </article>

              <article class="mx-auto max-w-sm pb-8 bg-cover bg-center cursor-pointer transform duration-500 hover:-translate-y-1 shadow-2xl rounded-xl">
                <img class="mx-auto mb-20 mt-10 w-40" src="https://penpot.app/images/open-source.webp" alt="" />
                <h2 class="text-center text-3xl mt-8 font-bold min-h-18 px-12">
                  Open Source power
                </h2>
                <p class="m-4 text-lg p-4 leading-relaxed text-center ">
                  Build for the community and empowered by the community. Extreme adaptability: contributions can range from add-ons and plugins to core functionality.
                </p>
              </article>
            </section>
          </section>
        </div> */}
      </MainLayout>

    </>
  )
}
