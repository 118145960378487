import React from "react";

import CollabICon from '../../assets/svg/Hanngshake.svg';
import UIUXIocn from '../../assets/svg/UIUX_icon.svg';
import DeadlineIcon from '../../assets/svg/Deadline_icon.svg';
import SEOIcon from '../../assets/svg/development_icon.svg';
import Testing from '../../assets/svg/SoftwareTesting_icon.svg';
import flexibleIcon from '../../assets/svg/flexible.svg';

export default function Featurelayout(){

    const features = [
        {
            icon: `${CollabICon}`,
            title: "Collbation",
            desc: "we believe in the power of collaboration to drive innovation and success. We work closely with our clients, partners, and stakeholders to foster meaningful collaborations that leverage collective expertise and resources. s"
        },
        {
            icon:`${SEOIcon}`,
            title: " SEO",
            desc: "By harnessing the power of SEO, we help businesses maximize their visibility, attract targeted traffic, and achieve sustainable growth in today's competitive online landscape."
        },
        {
            icon:`${UIUXIocn}`,
            title: "Design",
            desc: "Our team of talented designers combines creativity with user-centric principles to create intuitive and visually stunning interfaces. We prioritize user research, prototyping, and iterative testing to ensure that our designs not only look beautiful but also deliver seamless functionality and usability."
        },
        {
            icon:`${DeadlineIcon}`,
            title: "Attention to Deadline",
            desc: "With meticulous planning, efficient project management, and clear communication, we ensure that deadlines are met without compromising on quality. Our team thrives under pressure, consistently exceeding expectations and delivering results that align with our clients' timelines. You can trust us to prioritize your deadlines and deliver exceptional outcomes, no matter the scope or complexity of the project."
        },
        {
            icon:`${Testing}`,
            title: "Quality Assurance",
            desc: "we prioritize quality assurance to ensure that every aspect of your project meets the highest standards. Our dedicated team of quality assurance professionals meticulously tests and validates your products, services, or software solutions to guarantee flawless performance and user satisfaction."
        },
        {
            icon:`${flexibleIcon}`,
            title: "Flexible",
            desc: "we pride ourselves on our flexibility in adapting to the evolving needs of our clients. With a dynamic approach and a willingness to embrace change, we tailor our solutions to accommodate shifting priorities, requirements, and market conditions."
        },
    ]

    return (
        <section className="py-32 bg-stone-10  0">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8 ">
                <div className="max-w-screen-xl mx-auto space-y-3 sm:text-center py-8" data-aos="fade-up" data-aos-delay="200">
                    <p className="text-[#3E885B] text-3xl font-semibold sm:text-4xl">
                        Feature that seprate Us from Other  
                    </p>
                    <p>
                        Key features of the company that helps your business and Startup's Grow
                    </p>
                </div>
                <div className="mt-12">
                    <ul className="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3" data-aos="fade" data-aos-delay="800">
                        {
                            features.map((item, idx) => (
                                <li key={idx} className="flex gap-x-4 group hover:bg-[#3E885B] rounded-md bg-indigo-50 p-4 shadow-md">
                                    <div className="flex-none w-12 h-12 rounded-lg flex items-center  justify-center group hover:filter hover:brightness-100 hover:contrast-200 grayscale">
                                        <img src={item.icon} alt="" className="h-9 w-auto group-hover:stoke-white" />
                                    </div>
                                    <div>   
                                        <h4 className="text-lg group-hover:text-white text-[#3E885E] font-semibold">
                                            {item.title}
                                        </h4>
                                        <p className="mt-3 group-hover:text-white max-h-32 line-clamp-4  text-justify overflow-hidden">
                                            {item.desc} 
                                        </p>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}