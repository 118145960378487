import React from "react";
import MainLayout from "../../layouts/MainLayouts";
import { useState } from "react";
import locationPin from "../../../assets/svg/location-pin.svg";
import email from "../../../assets/svg/email.svg";
import contact from "../../../assets/svg/phonecall.png";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      // Form submission logic here (e.g., send data to server)
      console.log("Form submitted:", formData);
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.name.trim()) {
      errors.name = "Name is required";
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }
    if (!data.message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };
  return (
    <MainLayout>
      <section className="mt-10 mx-auto max-w-screen-xl pb-10 px-4 text-center md:px-8">
        <div className="heading text-center text-6xl text-[#3E885B]">
          Contact Us
        </div>
        <p className="pt-4 max-w-screen-md mx-auto whitespace-pre">
          if you have any problem Just Contact us for more information
        </p>
      </section>

      <section>
        <main className="mt-10 mx-auto max-w-screen-xl py-20 overflow-hidden md:flex sm:flex gap-10 md:px-8 px-4">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSeRzJEb0wr3j8vLn1hKJwBWdW--cjZmErwak5TKQnNZlmQRVA/viewform?embedded=true"
            width="640"
            height="2275"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>

          <section className="Name bg-[#3E885B] md:w-[100%] xl:w-[40%] sm:w-[100%] h-72 md:flex sm:flex mx-auto">
            <main className="p-10 mx-auto">
              <div className=" text-3xl md:text-3xl sm:text-2xl mb-5">
                <strong className="text-white">Contact Information</strong>
              </div>

              <article className="flex gap-5 mb-5">
                <img
                  src={locationPin}
                  alt="location pin "
                  className="h-6 w-auto"
                />
                <strong>
                  {" "}
                  <p className="text-white">
                    {" "}
                    Amarpath , Butwal-6, Laxmi Bank Building, Rupandehi{" "}
                  </p>{" "}
                </strong>
              </article>

              <article className="flex gap-5 mb-5 align-middle ">
                <img src={email} alt="location pin " className="h-6 w-auto" />
                <strong>
                  {" "}
                  <p className="text-white"> synthbitgroup@gmail.com </p>{" "}
                </strong>
              </article>

              <article className="flex gap-5 mb-5 align-middle">
                <img src={contact} alt="location pin " className="h-6 w-auto" />
                <strong>
                  {" "}
                  <p className="text-white">
                    {" "}
                    +977 9857075504, +977 9821430781{" "}
                  </p>{" "}
                </strong>
              </article>
            </main>
          </section>
        </main>
      </section>

      <section className="location mx-auto max-w-screen-xl p-4  text-[#fff]">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1766.286392968601!2d83.464482!3d27.699596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39968700617e1c89%3A0x6b734339d6ce40b6!2sSynth%20Bit%20Group%20Pvt%20Ltd!5e0!3m2!1sen!2snp!4v1715854838596!5m2!1sen!2snp"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
    </MainLayout>
  );
}

export default ContactUs;

{
  /* 

                    */
}
