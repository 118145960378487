import MainLayout from "../../layouts/MainLayouts";
import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

import ServicesLayout from "../../layouts/ServicesLayout";
import HeroSectionLayout from "../../layouts/HeroSectionLayout";
import BlogQuoteLayout from "../../layouts/BlockquoteLayout";
import TrustedLayout from "../../layouts/TrustedLayout";
import Featurelayout from "../../layouts/Featurelayout";
import ContactLayout from "../../layouts/ContactLayout";
import TeamsLayout from "../../layouts/TeamsLayout";
import TabLayout from "../../layouts/TabLayout";
import { Helmet } from "react-helmet";

function Home() {
  const texts = ["Collaborative ", "Computation ", "Comercialization "];

  const [currentText, setCurrentText] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 200 }); // Initialize AOS
  }, []);

  useEffect(() => {
    AOS.refresh({ duration: 200 });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentText((prevText) => (prevText + 1) % texts.length);
    }, 200);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Synthbit Group" />
        <meta
          property="og:description"
          content="At SynthBit, we are committed to delivering high-quality products tailored to meet the diverse needs of companies. Our approach involves close collaboration with our clients, fostering strong relationships built on trust and communication."
        />
        <meta
          property="og:image"
          content="../../assets/img/synthbitgroup pvt. ltd..png"
        />
        <meta property="og:url" content="https://www.synthbitgroup.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <MainLayout>
        <HeroSectionLayout></HeroSectionLayout>
        <TrustedLayout></TrustedLayout>
        <Featurelayout></Featurelayout>
        <BlogQuoteLayout></BlogQuoteLayout>
        <TabLayout></TabLayout>
        <ServicesLayout></ServicesLayout>
        <TeamsLayout></TeamsLayout>
        <ContactLayout></ContactLayout>
      </MainLayout>
    </>
  );
}

export default Home;
