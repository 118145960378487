import './App.css';
import About from './components/pages/about/About';
import Home from "./components/pages/home/Home"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Service from './components/pages/services/Service';
import ContactUs from './components/pages/contact/contact';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />
        <Route path="/contact" element={<ContactUs />} />
        
      </Routes>
    </BrowserRouter> 
  );
}

export default App;
