import React from 'react'
import MainLayout from '../../layouts/MainLayouts';
import BlogQuoteLayout from '../../layouts/BlockquoteLayout.jsx';
import SynthBitLogo from '../../../assets/img/synthbitgroup pvt. ltd..png';
import rocketImage from '../../../assets/svg/2588762_fast_optimize_rocket_seo_icon.svg';
import ideaImage from '../../../assets/svg/8679697_lightbulb_flash_idea_light_bulb_icon.svg';
;function About() {
    return (
        <>
            <MainLayout>

                <section className="mt-10 mx-auto max-w-screen-xl pb-10 px-4 items-center text-center md:px-8">
                    <div className="heading text-center text-6xl text-[#3E885B]">
                        About Us
                    </div>
                    <p className='pt-4 max-w-screen-md mx-auto whitespace-pre'>
                        We stand as an experienced and trustworthy technology ally, ready to help with your next venture.
                    </p>

                </section>

                <section className=' bg-indigo-50 col-start-auto'>
                    <main className="mt-10 mx-auto max-w-screen-xl py-12 items-center justify-between overflow-hidden md:flex sm:flex md:px-8 px-4">
                        <div class="mt-4 grid grid-cols-1 gap-4 py-5 md:grid-cols-2">

                            <div class="our-company flex items-center bg-white justify-center p-6 shadow-lg rounded-md" data-aos="fade-up">
                                <div className="company-discription text-justify flex flex-col align-middle">
                                    <img src={SynthBitLogo} class=" px-2 h-44 w-82" alt="bootwal" />
                                    <p class="about-txt">
                                    We elevate user experiences, providing businesses a competitive edge through seamless, technology-driven growth.
                                    </p>
                                </div>
                            </div>

                            <div class="align-items-center col-span-1 m-0 grid grid-cols-1 gap-4 md:col-span-1 md:grid-cols-2" data-aos="fade-up">
                                <div class="about-box my-4 text-center flex flex-col align-middle bg-white shadow-lg rounded-md p-6">
                                <img src={rocketImage} class=" px-2 mb-4 h-8 w-auto" alt="bootwal" />

                                    <h4 className='text-[#3E885B] text-3xl'>Mission</h4>
                                    <p>We develop intelligent systems and tools using AI and Automation for our clients to grow big.​</p>
                                </div>
                                <div class="about-box text-center flex flex-col align-middle bg-white shadow-md rounded-lg p-6">
                                <img src={ideaImage} class=" px-2 mb-4 h-8 w-auto" alt="bootwal" />

                                    <h4 className='text-[#3E885B] align-middle text-3xl'>Vision</h4>
                                    <p>Use enhanced technology to uplift society economically.</p>
                                </div>
                                <div class="about-box mt-4 text-center flex flex-col align-middle bg-white shadow-md rounded-lg p-6">
                                <img src={rocketImage} class=" px-2 mb-4 h-8 w-auto" alt="bootwal" />
                                    <h4 className='text-[#3E885B] text-3xl'>Values</h4>
                                    <p>Innovation, Excellence, Integrity, Collaboration, Empowerment</p>
                                </div>
                            </div>
                        </div>

                    </main>
                </section>

                <BlogQuoteLayout></BlogQuoteLayout>


                <div className="our-team">
                    hwllo this is a team section and i have  to  be specificition
                </div>

            </MainLayout>

        </>
    )
}

export default About
