import Navbar from "../sharedComponents/Navbar";
import Footer from "../sharedComponents/Footer";
import React from "react";
import FacebookMsg from "../FacebookMsg";


const MainLayout = ({children})=>{
    return(
        <>
            <Navbar />
            {children}
            <FacebookMsg />
            <Footer/>
        </>
    )
}

export default MainLayout;  