import { useEffect, useState } from 'react';
import React from 'react';
import Logo from '../../assets/img/synthbitgroup pvt. ltd..png';
import { Link } from "react-router-dom";

import WebIcon from '../../assets/svg/Network_web_icon.svg';
import DataScienceIcon from '../../assets/svg/Datasets_learning_icon.svg';
import SoftwareIcon from '../../assets/svg/Software_dev_icon.svg';
import SoftwareTestingIcon from '../../assets/svg/SoftwareTesting_icon.svg';
import UIUXIcon from '../../assets/svg/UIUX_icon.svg';
import DevOpsIcon from '../../assets/svg/Dev_edit_icon.svg';

const dropdownNavs = [

    {
        label: "Services", navs: [
            {
                title: "Software",
                desc: "Crafting tailored software solutions aligned with your vision, driving efficiency and innovation through precise coding.",
                path: "",
                icon: `${ SoftwareIcon }`,
            },
            {
                icon: `${WebIcon}`,
                title: " Mobile & Web Development",
                desc: "Creating seamless digital journeys with user-centric design, marrying aesthetics and functionality for unparalleled cross-platform experiences.",
                path: "",
            },
            {
                icon: `${SoftwareTestingIcon}`,
                title: "Software Testing",
                desc: "Ensuring impeccable software quality through rigorous testing, assuring reliability and user satisfaction with every release.",
                path: "",
            },
            {
                icon: `${DataScienceIcon}`,
                title: "Data Science and Machine Learning",
                desc: " Unveiling insights from complex data landscapes, empowering strategic decisions and breakthroughs through advanced analytics and machine learning.",
                path: "",
            },
            {
                icon: `${UIUXIcon}`,
                title: "UI & UX Design",
                desc: "Envisioning and crafting intuitive user experiences, where artistic UI meets seamless functionality, elevating brand value and user engagement.",
                path: "",
            },
            {
                icon: `${DevOpsIcon}`,
                title: "Dev-Ops",
                desc: "Building robust foundations and agile workflows for scalability and security while embracing continuous development methodologies.",
                path: "",
            }
        ]
    },
]

export default function NavBar() {

    const [scrollState, setScrollState] = useState(false)
    const [state, setState] = useState(false)
    const [drapdownState, setDrapdownState] = useState({ isActive: false, idx: null })

    // Replace javascript:void(0) paths with your paths
    const navigation = [
        { title: "Home", path: "/", isDrapdown: false },
        { title: "Services", path: "/services", isDrapdown: true, navs: dropdownNavs },
        { title: "About", path: "/about", isDrapdown: false },
        { title: "Contact", path: "/contact", isDrapdown: false }   

    ]

    useEffect(() => {
        document.onclick = (e) => {
            const target = e.target;
            if (!target.closest(".nav-menu")) setDrapdownState({ isActive: false, idx: null });
        };
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setScrollState(true);
            } else {
                setScrollState(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <nav className={`relative z-20 w-full md:sticky sm:bg-white md:top-0 md:left-0 md:text-sm md:border-none ${state ? "shadow-lg rounded-b-xl md:shadow-none" : ""} ${scrollState ? "bg-white shadow-md" : ""} `}>
                <div className="items-center gap-x-14 px-4 max-w-screen-xl mx-auto md:flex md:px-8">
                    <div className=" items-center flex justify-between py-3 md:py-5 md:flex">
                        <a href="javascript:void(0)">
                            <img
                                src={Logo}
                                width={120}
                                height={50}
                                alt="Float UI logo"
                            />
                        </a>
                        <div className="md:hidden">
                            <button className=" hover:text-[#3E885B]"
                                onClick={() => setState(!state)}
                            >
                                {
                                    state ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                            <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                                        </svg>

                                    )
                                }
                            </button>
                        </div>
                    </div>
                    <div className={`nav-menu flex-1 pb-3 mt-8  md:flex md:justify-end md:pb-0 md:mt-0 ${state ? 'block' : 'hidden'}`}>
                        <ul className="items-center justify-between space-y-6  md:flex md:space-x-6 md:space-y-0">
                            {
                                navigation.map((item, idx) => {
                                    return (
                                        <li key={idx}>
                                            {
                                                item.isDrapdown ? (
                                                    <button className="w-full flex items-center justify-between gap-1 font-bold hover:text-[#3E885B] group transition duration-300"
                                                        onClick={() => setDrapdownState({ idx, isActive: !drapdownState.isActive })}
                                                    >
                                                        <Link to={item.path} className="block  font-bold hover:text-[#3E885B] group transition duration-300 ">
                                                        {item.title}
                                                    </Link>
                                                        {
                                                            drapdownState.idx == idx && drapdownState.isActive ? (
                                                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                                    <path fillRule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clipRule="evenodd" />
                                                                </svg>

                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                                                </svg>
                                                            )
                                                        }

                                                    </button>
                                                ) : (
                                                    <Link to={item.path} className="block mr-6 font-bold hover:text-[#3E885B] group transition duration-300 ">
                                                        {item.title}
                                                    </Link>
                                                )
                                            }
                                            {
                                                item.isDrapdown && drapdownState.idx == idx && drapdownState.isActive ? (
                                                    <div className="mt-6 inset-x-0 md:bg-white top-20 w-full md:absolute md:border-y md:shadow-md md:mt-0">
                                                        <ul className='max-w-screen-xl mx-auto  '>
                                                            {item?.navs.map((dropdownItem, idx) => (
                                                                <li key={idx}>
                                                                    <ul className=' space-y-6 grid items-center gap-6 md:p-8 md:grid-cols-2 lg:grid-cols-3'>
                                                                        {dropdownItem.navs.map((navItem, idx) => (
                                                                            <li key={idx} className="group" defaultValue="home">
                                                                                <a href={navItem.path} className='flex gap-3 items-center'>
                                                                                    <div className='w-12 h-12 rounded-full bg-indigo-50 flex items-center justify-center duration-150 group-hover:bg-[#3E885B] group-hover:text-black md:w-14 md:h-14'>
                                                                                        <div className=' flex-2'>
                                                                                            <img src={navItem.icon} alt="" className="xl:h-8 sm:h-6 md:h-8" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='flex-1'>
                                                                                        <span className=" duration-200 group-hover:text-black text-sm font-medium md:text-base text-[#3E885B]">{navItem.title}</span>
                                                                                        <p className='text-sm text-justify group-hover:text-black mt-1'>{navItem.desc}</p>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ) : ""
                                            }
                                        </li>
                                    )
                                })
                            }
                            <div className='flex-1 items-center justify-end gap-x-6 space-y-3 md:flex md:space-y-0'>

                                <li>
                                    <button className="bg-transparent hover:bg-[#3E885B] text-blue-700 font-semibold hover:text-white py-2 px-4 border border-[#4484c2] hover:border-transparent rounded">
                                        Build With Us
                                    </button>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </nav>
            {
                state ? (
                    <div
                        className="z-10 fixed top-0 w-screen h-screen bg-black/20 backdrop-blur-sm md:hidden"
                        onClick={() => setState(false)}></div>
                ) : ""
            }
        </>
    )
}