"use client";
import React from 'react';
import { FacebookProvider, CustomChat } from 'react-facebook';

export default function FacebookMsg() {
  return (
    <>
      <FacebookProvider appId="361392389993415" chatSupport>
        <CustomChat pageId="228360123689179" minimized={true} />
      </FacebookProvider>
    </>
  )
}



